import React, { useMemo, useState, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MainContext } from '../common/types/mainContext';
import {
  ContentVisualizationMode,
  ExportProjectSelection,
  LocationInfo,
  ProjectSelection,
  ProjectsHierarchy,
  ReportItemInfo,
  ReportItems,
} from '../common/types/projectsHierarchy';
import { Routes } from '../routes';
import {
  CatchmentLocationsCountEnum,
  CatchmentMetricTypeEnum,
  CatchmentSocioDemoMetricEnum,
  CatchmentTypeEnum,
  ColorSchemeEnum,
  CrossVisitationChartValues,
  DataLevelEnum,
  LocationTypeEnum,
  MovementPeriodEnum,
  MovementVisitorTypeEnum,
  OverlayHotspotsEnum,
  PeriodsEnum,
  ReportItemType,
} from '../common/reportItems/types';

import { ReportItemsDetails } from '../common/types/reportItemsDetails';

import { GeneralPage } from '../pages';
import { User } from '../common/types/User';
import { ForgotPasswordPage } from '../pages/forgotPassword';
import { ForgotPasswordEmailSentPage } from '../pages/forgotPasswordEmailSent';
import { CreateNewPasswordPage } from '../pages/createNewPassword';
import { NewPasswordCreatedSuccessfullyPage } from '../pages/createNewPasswordSuccess';
import { BasicMapPopupProps, SwitchModes } from '../common/types/visualizationObjects';
import { useToggle } from '../common/hooks';
import '../styles/main.scss';
import '../styles/global.scss';
import { MapOptions } from '../common/maps/mapBase';
import { PricingPage } from '../pages/pricing';
import { SortByEnum as SortByReportEnum } from '../projects/panelContent/reports/enums';
import { SortByEnum as SortByProjectEnum } from '../projects/panelContent/projects/enums';
import { CircleLoader } from '../common/loaders';
import { SignUpPage } from '../pages/signUp';
import { ActivateAccountPage } from '../pages/activateAccount';
import { ActivateAccountCongratulationsPage } from '../pages/activateAccountCongratulations';
import { SelfSignUpFinalPage} from "../pages/selfSignUpFinal";
import { Limitations } from '../common/types/limitations';

const MainPage = () => {
  const { path, url } = useRouteMatch();

  const [selection, setSelection] = useState<ProjectSelection>({
    selectedProject: null,
    selectedReport: null,
    selectedReportItem: null,
  });
  const [exportSelection, setExportSelection] = useState<ExportProjectSelection>({
    selectedReportItem: null,
  });
  const [isSelectionDataLoaded, toggleIsSelectionDataLoaded] = useToggle(false);

  const [reportItemsDetails, setReportItemsDetails] = useState<ReportItemsDetails>({
    period: PeriodsEnum.MONTHLY,
    retentionPeriod: PeriodsEnum.QUARTERLY,
    items: [],
    metricType: '',
    colorScheme: ColorSchemeEnum.COLORFUL,
    catchmentType: CatchmentTypeEnum.HOME,
    catchmentLocationsCount: CatchmentLocationsCountEnum.SINGLE,
    catchmentDataOverlay: false,
    catchmentMetricType: CatchmentMetricTypeEnum.DESTINATION,
    catchmentSocioDemoMetric: CatchmentSocioDemoMetricEnum.INHABITANTS,
    selectedCountry: null,
    catchmentTreshold: {
      min: 0.001,
      max: 1,
    },
    movementTreshold: {
      min: 0,
      max: 1,
    },
    showHoursByWeek: true,
    dataLevel: DataLevelEnum.LOCATION,
    locationType: LocationTypeEnum.POIS,
    hotspotOverlay: OverlayHotspotsEnum.YES,
    movementPeriod: MovementPeriodEnum.BEFORE,
    movementType: MovementVisitorTypeEnum.VISITORS,
    showAverageValuesInChart: true,
    crossVisitationChartValues: CrossVisitationChartValues.CROSS_VISITATION,
    csv: '',
    locations: [],
  });

  const [user, setUser] = useState({
    userId: '',
    userRole: '',
    accountId: '',
    userName: '',
    userEmail: '',
    avatar: '',
    companyLogo: '',
  });
  const [selectedLocations, setSelectedLocations] = useState<LocationInfo[]>([]);
  const [primaryLocationId, setPrimaryLocationId] = useState<string | null>(null);
  const [primaryLocationCoordinates, setPrimaryLocationCoordinates] = useState<{
    longitude: null | number;
    latitude: null | number;
  }>({ longitude: null, latitude: null });

  const [projectsHierarchy, setProjectsHierarchy] = useState<ProjectsHierarchy>([]);
  const [contentVisualizationMode, setContentVisualizationMode] = useState<ContentVisualizationMode>(
    ContentVisualizationMode.MAP,
  );
  const [applicationLoadProgress, setApplicationLoadProgress] = useState(0);
  const [limitations, setLimitations] = useState<Limitations>({
    maximumNumberOfLocations: 30,
    maximumNumberOfClusters: 10,
    polygonSize: 32000,
    aoiPolygonSize: 5000000,
  });

  const [selectedCatchmentLayer, setSelectedCatchmentLayer] = useState<SwitchModes>(SwitchModes.POSTAL_CODES);
  const [selectedMovementMode, setSelectedMovementMode] = useState<SwitchModes>(SwitchModes.HEATMAP);
  const [absorptionSourceLocation, setAbsorptionSourceLocation] = useState<LocationInfo | null>(null);
  const [attractionSourceLocation, setAttractionSourceLocation] = useState<LocationInfo | null>(null);
  const [ticket, setTicket] = useState('');

  const [search, setSearch] = useState({ projects: '', reports: '' });
  const [sortBy, setSortBy] = useState<{ projects: SortByProjectEnum; reports: SortByReportEnum }>({
    projects: SortByReportEnum.NAME,
    reports: SortByReportEnum.NAME,
  });
  const selectedLocationsIds = useMemo(() => selectedLocations.map((location) => location.id), [selectedLocations]);

  const [selectedTimeFrames, setSelectedTimeFrames] = useState<string[] | null>(null);
  const [mapOptions, setMapOptions] = useState<MapOptions>({
    default: { zoom: null, latitude: null, longitude: null },
    catchment: { zoom: null, latitude: null, longitude: null },
    catchmentTripRoutes: { zoom: null, latitude: null, longitude: null },
    movement: { zoom: null, latitude: null, longitude: null },
  });
  const [popup, setPopup] = useState<BasicMapPopupProps>({
    ABSORPTION: { coordinates: [], title: '' },
    ATTRACTION: { coordinates: [], title: '' },
    DURATION: { coordinates: [], title: '' },
    FOOTFALL: { coordinates: [], title: '' },
    LOYALTY: { coordinates: [], title: '' },
    MOVEMENT: { coordinates: [], title: '' },
    RETENTION: { coordinates: [], title: '' },
    SEASONALITY: { coordinates: [], title: '' },
    [ReportItemType.CATCHMENT]: {
      title: '',
      coordinates: [],
    },
  });

  const [resetMapViewport, toggleResetMapViewPort] = useToggle(false);

  const [isDashboardLoaderVisible, setIsDashboardLoaderVisible] = useState<boolean>(false);
  const [dashboardLoaderProgress, setDashboardLoaderProgress] = useState<number>(0);
  const [dashboardLoaderLabel, setDashboardLoaderLabel] = useState<string>('Loading...');

  const [createReportStep, setCreateReportStep] = useState<number>(1);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');

  // const changeSelectedLocationsHandler = (newLocations: LocationInfo[]) => {
  //   setSelectedLocations(newLocations);
  // };

  const changePrimaryLocation = (newPrimaryLocationId: string | null) => {
    setPrimaryLocationId(newPrimaryLocationId);
    if (newPrimaryLocationId) {
      const country = reportItemsDetails.locations.find(
        (location) => location.locationId === newPrimaryLocationId,
      )?.country;
      updateReportItemsDetailsValue('selectedCountry', country);
    } else {
      updateReportItemsDetailsValue('selectedCountry', 'de');
    }
  };

  const updateSelectedReportItem = (item: ReportItemInfo<ReportItems> | null) => {
    setSelection((prevState) => ({
      ...prevState,
      selectedReportItem: item,
    }));
  };

  const updateReportItemOfSelectedReport = (
    reportItem: ReportItemInfo<ReportItems>,
    forPrimaryLocationId: string | null = null,
  ) => {
    if (forPrimaryLocationId === null || forPrimaryLocationId === primaryLocationId) {
      setSelection((prevState) => ({
        ...prevState,
        selectedReport: prevState.selectedReport
          ? {
              ...prevState.selectedReport,
              report_items: prevState.selectedReport.report_items.map((item) => {
                if (item.type === reportItem.type) {
                  return reportItem;
                }
                return item;
              }),
            }
          : null,
        selectedReportItem:
          prevState.selectedReportItem && prevState.selectedReportItem.id === reportItem.id
            ? reportItem
            : prevState.selectedReportItem,
      }));
    }
  };

  const updateReportItemsDetailsItems = (type: string, items: ReportItemInfo<ReportItems>[]) => {
    setReportItemsDetails((prevState) => {
      const isAlreadyPresent = prevState.items.find((item) => item.id === items[0].id);
      return {
        ...prevState,
        items: isAlreadyPresent
          ? prevState.items.map((item) => (item.id === items[0].id ? items[0] : item))
          : [...prevState.items, ...items],
      };
    });
  };

  const clearReportItemsDetails = () => {
    setReportItemsDetails({
      period: PeriodsEnum.MONTHLY,
      retentionPeriod: PeriodsEnum.QUARTERLY,
      items: [],
      metricType: '',
      colorScheme: ColorSchemeEnum.COLORFUL,
      catchmentType: CatchmentTypeEnum.HOME,
      catchmentLocationsCount: CatchmentLocationsCountEnum.SINGLE,
      catchmentDataOverlay: false,
      catchmentMetricType: CatchmentMetricTypeEnum.DESTINATION,
      catchmentSocioDemoMetric: CatchmentSocioDemoMetricEnum.INHABITANTS,
      selectedCountry: null,
      catchmentTreshold: {
        min: 0.001,
        max: 1,
      },
      movementTreshold: {
        min: 0,
        max: 1,
      },
      showHoursByWeek: true,
      dataLevel: DataLevelEnum.LOCATION,
      locationType: LocationTypeEnum.POIS,
      hotspotOverlay: OverlayHotspotsEnum.YES,
      movementPeriod: MovementPeriodEnum.BEFORE,
      movementType: MovementVisitorTypeEnum.VISITORS,
      showAverageValuesInChart: true,
      crossVisitationChartValues: CrossVisitationChartValues.CROSS_VISITATION,
      csv: '',
      locations: [],
    });
  };

  const updateExportProjectSelection = (type: 'selectedReportItem', data: ReportItemInfo<ReportItems> | null) => {
    setExportSelection((prevState) => ({ ...prevState, [type]: data }));
  };

  const updateReportItemsDetailsMetricType = (metricType: string) => {
    setReportItemsDetails((prevState) => ({ ...prevState, metricType }));
  };

  const updateReportItemsDetailsValue = (name: string, value: any) => {
    setReportItemsDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const saveUser = (data: User) => {
    setUser(data);
  };

  const changeApplicationLoadProgress = (data: number) => {
    setApplicationLoadProgress(data);
  };

  const onSearchItems = (type: string, value: string) => {
    setSearch((prevState) => ({ ...prevState, [type]: value }));
  };

  const onChangeSortBy = (type: 'projects' | 'reports', value: SortByReportEnum | SortByProjectEnum) => {
    setSortBy((prevState) => ({ ...prevState, [type]: value }));
  };

  const onSaveTicket = (data: string) => {
    setTicket(data);
  };

  const onChangeGlobalMapZoom = (data: Partial<MapOptions>) => {
    setMapOptions((prevState) => ({ ...prevState, ...data }));
  };

  const onChangeResetMapViewport = (data: boolean) => {
    toggleResetMapViewPort(data);
  };

  const updateDashboardLoader = (type: ReportItemType, visible: boolean, label?: string, progress?: number) => {
    if (type === selection.selectedReportItem?.type) {
      setIsDashboardLoaderVisible(visible);
      setDashboardLoaderLabel(label || '');
      setDashboardLoaderProgress(progress || 0);
    }
  };

  const updateLimitations = (
    maximumNumberOfLocations: number,
    maximumNumberOfClusters: number,
    polygonSize: number,
    aoiPolygonSize: number,
  ) => {
    setLimitations({
      maximumNumberOfLocations,
      maximumNumberOfClusters,
      polygonSize,
      aoiPolygonSize,
    });
  };

  return (
    <MainContext.Provider
      value={{
        projectsHierarchy,
        // projectsHierarchyDate: new Date(),
        setProjectsHierarchy,
        selection,
        updateSelection: setSelection,
        contentVisualizationMode,
        setContentVisualizationMode,
        updateSelectedReportItem,
        updateReportItemOfSelectedReport,
        reportItemsDetails,
        updateReportItemsDetailsItems,
        updateReportItemsDetailsMetricType,
        updateReportItemsDetailsValue,
        selectedLocations,
        selectedLocationsIds,
        setSelectedLocations,
        primaryLocationId,
        setPrimaryLocationId: changePrimaryLocation,
        selectedTimeFrames,
        setSelectedTimeFrames,
        saveUser,
        user,
        applicationLoadProgress,
        changeApplicationLoadProgress,
        catchmentLayer: selectedCatchmentLayer,
        setCatchmentLayer: setSelectedCatchmentLayer,
        movementMode: selectedMovementMode,
        setMovementMode: setSelectedMovementMode,
        absorptionSourceLocation,
        setAbsorptionSourceLocation,
        attractionSourceLocation,
        setAttractionSourceLocation,
        onSearchItems,
        search,
        onSaveTicket,
        ticket,
        toggleIsSelectionDataLoaded,
        isSelectionDataLoaded,
        popup,
        setPopup,
        onChangeGlobalMapZoom,
        mapOptions,
        clearReportItemsDetails,
        resetMapViewport,
        onChangeResetMapViewport,
        isDashboardLoaderVisible,
        dashboardLoaderProgress,
        dashboardLoaderLabel,
        updateDashboardLoader,
        selectedLanguage,
        setSelectedLanguage,
        exportSelection,
        updateExportProjectSelection,
        sortBy,
        onChangeSortBy,
        createReportStep,
        setCreateReportStep,
        setPrimaryLocationCoordinates,
        primaryLocationCoordinates,
        limitations,
        setLimitations: updateLimitations,
      }}
    >
      <Suspense fallback={<CircleLoader withBackground />}>
        <Switch>
          <Route exact path={Routes.forgotPassword}>
            <ForgotPasswordPage />
          </Route>{' '}
          <Route exact path={Routes.signUp}>
            <SignUpPage />
          </Route>{' '}
          <Route exact path={Routes.forgotPasswordSuccessfully}>
            <ForgotPasswordEmailSentPage />
          </Route>{' '}
          <Route exact path={Routes.signUpActivateAccount}>
            <ActivateAccountPage />
          </Route>{' '}
          <Route exact path={Routes.signUpFinal}>
            <SelfSignUpFinalPage />
          </Route>{' '}
          <Route exact path={Routes.signUpActivateAccountCongratulations}>
            <ActivateAccountCongratulationsPage />
          </Route>
          <Route exact path={Routes.createNewPasswordSuccess}>
            <NewPasswordCreatedSuccessfullyPage />
          </Route>
          <Route exact path={Routes.createNewPassword}>
            <CreateNewPasswordPage />
          </Route>
          <Route exact path={Routes.pricing}>
            <PricingPage />
          </Route>
          <Route>
            <GeneralPage />
          </Route>
        </Switch>
      </Suspense>
    </MainContext.Provider>
  );
};

export default MainPage;
