import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MainContext } from '../common/types/mainContext';
import { ReportData, LocationsData, ReportItemsData, defaultReportData, defaultReportItemsData } from './types';
import { StepOne, StepTwo, StepThree, StepFour } from './steps';
import { createReport } from '../api/report/create';
import { createProjectLocations, getProjectLocations } from '../api';
import ConfirmCloseTooltip from './steps/confirmClose';
import styles from './createReportPage.module.scss';

const CreateReportPage = () => {
  const { createReportStep, setCreateReportStep } = useContext(MainContext);
  const [reportData, setReportData] = useState<ReportData>(defaultReportData);
  const [locationsData, setLocationsData] = useState<LocationsData>({ locations: [] });
  const [reportItemsData, setReportItemsData] = useState<ReportItemsData>(defaultReportItemsData);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const history = useHistory();
  const [isCancelReportOpen, toggleCancelReport] = useState<boolean>(false);

  const updateLocations = async () => {
    const newLocations = locationsData.locations.filter((loc) => loc.isNew);
    if (newLocations.length > 0) {
      await createProjectLocations(reportData.projectId, newLocations);
      const projectLocations = await getProjectLocations(reportData.projectId);
      const reportLocations = projectLocations.filter((projLoc) =>
        locationsData.locations.some((loc) => loc.id === projLoc.id || (loc.isNew && loc.name === projLoc.name)),
      );
      setLocationsData({ locations: reportLocations });
      return reportLocations;
    }
    return locationsData.locations;
  };

  const onReportSave = async () => {
    if (reportData.name.length > 0) {
      let reportItems = { ...reportItemsData };
      try {
        const reportLocations = await updateLocations();
        if (reportItemsData.attraction_isActive || reportItemsData.absorption_isActive) {
          const attractionPois = reportItemsData.attraction_pois.map((poi) =>
            poi.isNew ? reportLocations.find((loc) => loc.name === poi.name) || poi : poi,
          );
          const attractionAois = reportItemsData.attraction_aois.map((aoi) =>
            aoi.isNew ? reportLocations.find((loc) => loc.name === aoi.name) || aoi : aoi,
          );
          const absorptionPois = reportItemsData.absorption_pois.map((poi) =>
            poi.isNew ? reportLocations.find((loc) => loc.name === poi.name) || poi : poi,
          );
          const absorptionAoi = reportItemsData.absorption_aoi?.isNew
            ? reportLocations.find((loc) => loc.name === reportItemsData.absorption_aoi?.name)
            : reportItemsData.absorption_aoi;
          reportItems = {
            ...reportItems,
            attraction_pois: attractionPois,
            attraction_aois: attractionAois,
            absorption_pois: absorptionPois,
            absorption_aoi: absorptionAoi || null,
          };
          setReportItemsData(reportItems);
        }

        await createReport(
          reportData,
          reportLocations.map((loc) => loc.id.toString()),
          reportItems,
        );
        setCreateReportStep(4);
      } catch (error: any) {
        console.log(error.response);
        if (error.response?.data?.reason) {
          setErrorMessage(`Error: ${error.response?.data?.reason}`);
        } else if (error.response?.data[0]?.reason && error.response.data[0].reason.length > 0) {
          setErrorMessage(`Error: ${error.response.data[0].reason}`);
        } else {
          setErrorMessage('Unexpected error in report creation process');
        }
      }
    }
  };

  const onConfirmCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (createReportStep !== 1) {
      setCreateReportStep(1);
    }
  }, []);

  useEffect(() => {
    setErrorMessage(null);
  }, [createReportStep]);

  const closeCancelWindow = () => toggleCancelReport(false);
  const openCancelWindow = () => toggleCancelReport(true);

  return (
    <>
      {isCancelReportOpen && (
        <div className={`${styles.tooltip} ${styles.unSave} ${createReportStep === 1 ? styles.unSaveRight : ''}`}>
          <ConfirmCloseTooltip onNo={closeCancelWindow} onYes={onConfirmCancel} />
        </div>
      )}
      {createReportStep === 1 && (
        <StepOne data={reportData} setData={setReportData} navigate={setCreateReportStep} onCancel={openCancelWindow} />
      )}
      {createReportStep === 2 && (
        <StepTwo
          projectId={reportData.projectId}
          data={locationsData}
          setData={setLocationsData}
          navigate={setCreateReportStep}
          onCancel={openCancelWindow}
          reportCategory={reportData.category}
        />
      )}
      {createReportStep === 3 && (
        <StepThree
          data={reportItemsData}
          setData={setReportItemsData}
          locationsData={locationsData}
          navigate={setCreateReportStep}
          onSave={onReportSave}
          errorMessage={errorMessage}
          onCancel={openCancelWindow}
          isAoi={reportData.category === 'AOI_DEFAULT'}
        />
      )}
      {createReportStep === 4 && <StepFour />}
    </>
  );
};
export default CreateReportPage;
