import request from 'superagent';
import { Dispatch } from 'redux';
import {
  AddAccountErrorAction,
  AddAccountSuccessAction,
  AddUserErrorAction,
  AddUserSuccessAction,
  ChangePasswordErrorAction,
  ChangePasswordSuccessAction,
  ChangeProjectStatusErrorAction,
  ChangeProjectStatusSuccessAction,
  ChangeReportStatusErrorAction,
  ChangeReportStatusSuccessAction,
  ClearErrorMessageAction,
  CreateCsvPoisErrorAction,
  CreatePoiErrorAction,
  CreatePoiSuccessAction,
  CreateProjectErrorAction,
  CreateProjectSuccessAction,
  CreateReportErrorAction,
  CreateReportItemsErrorAction,
  CreateReportItemsSuccessAction,
  CreateReportSuccessAction,
  DeletePoisErrorAction,
  DeletePoisSuccessAction,
  DeleteProjectErrorAction,
  DeleteProjectSuccessAction,
  DeleteReportErrorAction,
  DeleteReportItemErrorAction,
  DeleteReportItemSuccessAction,
  DeleteReportSuccessAction,
  RecalculateRIsSuccessAction,
  RecalculateRIsErrorAction,
  EditReportItemDescriptionErrorAction,
  EditReportItemDescriptionSuccessAction,
  EditReportItemErrorAction,
  EditReportItemSettingDefinitionsSuccessAction,
  EditReportItemSuccessAction,
  FetchAccountsErrorAction,
  FetchAccountsSuccessAction,
  FetchFfiPlansErrorAction,
  FetchFfiPlansSuccessAction,
  FetchUserErrorAction,
  FetchUserRolesErrorAction,
  FetchUserRolesSuccessAction,
  FetchUsersErrorAction,
  FetchUsersSuccessAction,
  FetchUserSuccessAction,
  ForgotPasswordErrorAction,
  ForgotPasswordSuccessAction,
  GenerateRIThumbnailsErrorAction,
  GenerateRIThumbnailsSuccessAction,
  GetOptionsProjectStatusesErrorAction,
  GetOptionsProjectStatusesSuccessAction,
  GetProjectStatusErrorAction,
  GetProjectStatusSuccessAction,
  HideSuccessIfRequiredMessageAction,
  LoadCountriesSuccessAction,
  LoadLocationsSuccessAction,
  LoadProjectsSuccessAction,
  LoadReportItemSettingDefinitionsSuccessAction,
  LoadReportItemTypesSuccess,
  LoadReportsSuccess,
  LoginErrorAction,
  LoginSuccessAction,
  PlacenseThunkAction,
  ResetBatchPoiUploadResults,
  ResetPasswordErrorAction,
  ResetPasswordSuccessAction,
  ShowSuccessMessageAction,
  UpdateProjectErrorAction,
  UpdateProjectSuccessAction,
  UpdateReportErrorAction,
  UpdateReportItemVisualizationUrlSuccessAction,
  UpdateReportSuccessAction,
  UpdateUserErrorAction,
  UpdateUserSuccessAction,
  CreatePostProcessingSettingsSuccess,
  CreatePostProcessingSettingsError,
  EditPostProcessingSettingsSuccessAction,
  EditPostProcessingSettingsErrorAction,
  DeletePostProcessingSettingsSuccessAction,
  DeletePostProcessingSettingsErrorAction,
  GetAllPostProcessingSettingsSuccessAction,
  GetAllPostProcessingSettingsErrorAction,
  GetPostProcessingSettingsByNameSuccessAction,
  GetPostProcessingSettingsByNameErrorAction,
  GetPostProcessingSettingsByCategorySuccessAction,
  GetPostProcessingSettingsByCategoryErrorAction,
} from './actionTypes';
import { SettingDefinitionsForItems } from '../types/reportItemSettingDefinition';
import { API_URL } from '../api';
import { Location } from '../types/location';
import { IReportCategory, PostProcessingSetting, Report, ReportItem } from '../types/report';
import { successBoxDisplayTimeInMillis } from '../reducers/successBoxReducer';
import { Project } from '../types/project';
import { Country } from '../types/country';
import { Account } from '../types/Account';
import { AddAccount } from '../types/AddAccount';
import { AddUser } from '../types/AddUser';
import { User } from '../types/User';
import UserRole from '../types/UserRole';
import FfiPlan from '../types/FfiPlan';
import { AddPoiResult, ErrorPoiResult } from '../reducers/poiManagementReducers';
import { SharedUser } from '../types/SharedUser';
import { CollaborationEnum } from '../components/pages/reportsPage/collaboration';

export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const CHANGE_PASSWORD_SUCCESS = 'FETCH_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'FETCH_PASSWORD_ERROR';
export const CHANGE_PROJECT_STATUS_SUCCESS = 'FETCH_PROJECTS_STATUS_SUCCESS';
export const CHANGE_PROJECT_STATUS_ERROR = 'FETCH_PROJECT_STATUS_ERROR';
export const CHANGE_REPORT_STATUS_SUCCESS = 'CHANGE_REPORT_STATUS_SUCCESS';
export const CHANGE_REPORT_STATUS_ERROR = 'CHANGE_REPORT_STATUS_ERROR';
export const GET_PROJECT_STATUS_SUCCESS = 'GET_PROJECT_STATUS_SUCCESS';
export const GET_PROJECT_STATUS_ERROR = 'GET_PROJECT_STATUS_ERROR';
export const GET_OPTIONS_PROJECT_STATUSES_SUCCESS = 'GET_OPTIONS_PROJECT_STATUSES_SUCCESS';
export const GET_OPTIONS_PROJECT_STATUSES_ERROR = 'GET_OPTIONS_PROJECT_STATUSES_ERROR';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'FETCH_ACCOUNTS_ERROR';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_ERROR = 'UPDATE_REPORT_ERROR';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_ERROR = 'DELETE_REPORT_ERROR';
export const RECALCULATE_RIS_SUCCESS = 'RECALCULATE_RIS_SUCCESS';
export const RECALCULATE_RIS_ERROR = 'RECALCULATE_RIS_ERROR';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';
export const CREATE_REPORT_ERROR = 'CREATE_REPORT_ERROR';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const FETCH_FFI_PLANS_SUCCESS = 'FETCH_FFI_PLANS_SUCCESS';
export const FETCH_FFI_PLANS_ERROR = 'FETCH_FFI_PLANS_ERROR';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_ERROR = 'FETCH_USER_ROLES_ERROR';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_ERROR = 'ADD_ACCOUNT_ERROR';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const GENERATE_RI_THUMBNAILS_SUCCESS = 'GENERATE_RI_THUMBNAILS_SUCCESS';
export const GENERATE_RI_THUMBNAILS_ERROR = 'GENERATE_RI_THUMBNAILS_ERROR';
export const CREATE_PPS_SUCCESS = 'CREATE_PPS_SUCCESS';
export const CREATE_PPS_ERROR = 'CREATE_PPS_ERROR';
export const EDIT_POST_PROCESSING_SETTINGS_SUCCESS = 'EDIT_POST_PROCESSING_SETTINGS_SUCCESS';
export const EDIT_POST_PROCESSING_SETTINGS_ERROR = 'EDIT_POST_PROCESSING_SETTINGS_ERROR';
export const DELETE_POST_PROCESSING_SETTINGS_SUCCESS = 'DELETE_POST_PROCESSING_SETTINGS_SUCCESS';
export const DELETE_POST_PROCESSING_SETTINGS_ERROR = 'DELETE_POST_PROCESSING_SETTINGS_ERROR';
export const GET_ALL_POST_POST_PROCESSING_SETTINGS_SUCCESS = 'GET_ALL_POST_POST_PROCESSING_SUCCESS';
export const GET_ALL_POST_POST_PROCESSING_SETTINGS_ERROR = 'GET_ALL_POST_POST_PROCESSING_ERROR';
export const GET_POST_PROCESSING_SETTINGS_BY_NAME_SUCCESS = 'GET_POST_PROCESSING_SETTINGS_BY_NAME_SUCCESS';
export const GET_POST_PROCESSING_SETTINGS_BY_NAME_ERROR = 'GET_POST_PROCESSING_SETTINGS_BY_NAME_ERROR';
export const GET_POST_PROCESSING_SETTINGS_BY_CATEGORY_SUCCESS = 'GET_POST_PROCESSING_SETTINGS_BY_CATEGORY_SUCCESS';
export const GET_POST_PROCESSING_SETTINGS_BY_CATEGORY_ERROR = 'GET_POST_PROCESSING_SETTINGS_BY_CATEGORY_ERROR';

const forbiddenHandler = (req: any) => {
  req.on('response', (res: any) => {
    if (res?.status === 403) {
      localStorage.clear();
      window.location.replace('/login');
    }
  });
};

export const clearErrorMessage = (): ClearErrorMessageAction => ({
  type: CLEAR_ERROR_MESSAGE,
  payload: {},
});

export const updateUser = (user: AddUser, callbackOnSuccess: () => void) => (dispatch: Dispatch) => {
  request
    .put(`${API_URL}/api/users/${user.userId}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .send({
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      phone: user.phone,
      title: user.title,
      status: user.status,
      ffiPlans: Object.keys(user.ffiPlans).filter((projectId) => user.ffiPlans[projectId]), // TODO check how ffi plans are selected
      notifyUser: user.notifyUser,
      accountId: user.accountId,
    })
    .then(() => {
      dispatch(updateUserSuccess());
      callbackOnSuccess();
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(updateUserError(reason));
    });
};

export const updateUserSuccess = (): UpdateUserSuccessAction => ({
  type: UPDATE_USER_SUCCESS,
  payload: {},
});

export const updateUserError = (reason: string): UpdateUserErrorAction => ({
  type: UPDATE_USER_ERROR,
  payload: {
    reason,
  },
});

export const fetchUser = (userId: string, callbackOnSuccess: (user: User) => void) => (dispatch: Dispatch) => {
  request
    .get(`${API_URL}/api/users/${userId}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then((response) => {
      const user = response.body;
      dispatch(fetchUserSuccess(user));
      callbackOnSuccess(user);
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(fetchUserError(reason));
    });
};

export const fetchUserSuccess = (user: User): FetchUserSuccessAction => ({
  type: FETCH_USER_SUCCESS,
  payload: {
    user,
  },
});

export const fetchUserError = (reason: string): FetchUserErrorAction => ({
  type: FETCH_USER_ERROR,
  payload: {
    reason,
  },
});

export const fetchUserRoles = () => (dispatch: Dispatch) => {
  request
    .get(`${API_URL}/api/users/roles`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then((response) => {
      dispatch(fetchUserRolesSuccess(response.body));
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(fetchUserRolesError(reason));
    });
};

export const fetchUserRolesSuccess = (userRoles: UserRole[]): FetchUserRolesSuccessAction => ({
  type: FETCH_USER_ROLES_SUCCESS,
  payload: {
    userRoles,
  },
});

export const fetchUserRolesError = (reason: string): FetchUserRolesErrorAction => ({
  type: FETCH_USER_ROLES_ERROR,
  payload: {
    reason,
  },
});

export const fetchFfiPlans = () => (dispatch: Dispatch) => {
  request
    .get(`${API_URL}/api/groups/shared`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then((response) => {
      dispatch(fetchFfiPlansSuccess(response.body));
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(fetchFfiPlansError(reason));
    });
};

export const fetchFfiPlansSuccess = (ffiPlans: FfiPlan[]): FetchFfiPlansSuccessAction => ({
  type: FETCH_FFI_PLANS_SUCCESS,
  payload: {
    ffiPlans,
  },
});

export const fetchFfiPlansError = (reason: string): FetchFfiPlansErrorAction => ({
  type: FETCH_FFI_PLANS_ERROR,
  payload: {
    reason,
  },
});

export const fetchUsers = (accountsIds: string[]) => (dispatch: Dispatch) => {
  request
    .get(`${API_URL}/api/users?accountIds=${accountsIds.join(',')}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then((response) => {
      dispatch(fetchUsersSuccess(response.body));
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(fetchUsersError(reason));
    });
};

export const fetchUsersSuccess = (users: User[]): FetchUsersSuccessAction => ({
  type: FETCH_USERS_SUCCESS,
  payload: {
    users,
  },
});

export const fetchUsersError = (reason: string): FetchUsersErrorAction => ({
  type: FETCH_USERS_ERROR,
  payload: {
    reason,
  },
});

export const addUser = (newUser: AddUser, callbackOnSuccess: () => void) => (dispatch: Dispatch) => {
  request
    .post(`${API_URL}/api/users`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .send({
      ...newUser,
      ffiPlans: Object.keys(newUser.ffiPlans).filter((projectId) => newUser.ffiPlans[projectId]),
    })
    .then(() => {
      dispatch(addUserSuccess());
      callbackOnSuccess();
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(changePasswordError(reason));
    });
};

export const addUserSuccess = (): AddUserSuccessAction => ({
  type: ADD_USER_SUCCESS,
  payload: {},
});

export const addUserError = (reason: string): AddUserErrorAction => ({
  type: ADD_USER_ERROR,
  payload: {
    reason,
  },
});

export const changePassword =
  (currentPassword: string, newPassword: string, reTypedNewPassword: string, callbackOnSuccess: () => void) =>
  (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/users/changePassword`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .send({ currentPassword, newPassword, reTypedNewPassword })
      .then(() => {
        dispatch(changePasswordSuccess());
        callbackOnSuccess();
      })
      .catch((error) => {
        const reason = error?.response?.body?.reason;
        dispatch(changePasswordError(reason));
      });
  };

export const changePasswordForUser =
  (userName: string, newPassword: string, callbackOnSuccess: () => void) => (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/users/changePasswordForUser`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .send({ userName, newPassword })
      .then(() => {
        dispatch(changePasswordSuccess());
        callbackOnSuccess();
      })
      .catch((error) => {
        const reason = error?.response?.body?.reason;
        dispatch(changePasswordError(reason));
      });
  };

export const changePasswordSuccess = (): ChangePasswordSuccessAction => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: {},
});

export const changePasswordError = (reason: string): ChangePasswordErrorAction => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: {
    reason,
  },
});

export const changeProjectStatus = (projectId: string, status: string) => (dispatch: Dispatch) => {
  request
    .put(`${API_URL}/api/projects/${projectId}/status/${status}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .send({ status })
    .then(() => {
      dispatch(changeProjectStatusSuccess(status));
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(changeProjectStatusError(reason));
    });
};

export const changeProjectStatusSuccess = (status: string): ChangeProjectStatusSuccessAction => ({
  type: CHANGE_PROJECT_STATUS_SUCCESS,
  payload: {
    status,
  },
});

export const changeProjectStatusError = (reason: string): ChangeProjectStatusErrorAction => ({
  type: CHANGE_PROJECT_STATUS_ERROR,
  payload: {
    reason,
  },
});

export const changeReportStatus = (reportId: string, status: string) => (dispatch: Dispatch) => {
  request
    .put(`${API_URL}/api/report/${reportId}/status/${status}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then(() => {
      dispatch(changeReportStatusSuccess(reportId, status));
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(changeReportStatusError(reason));
    });
};

export const changeReportStatusSuccess = (reportId: string, status: string): ChangeReportStatusSuccessAction => ({
  type: CHANGE_REPORT_STATUS_SUCCESS,
  payload: {
    reportId,
    status,
  },
});

export const changeReportStatusError = (reason: string): ChangeReportStatusErrorAction => ({
  type: CHANGE_REPORT_STATUS_ERROR,
  payload: {
    reason,
  },
});

export const fetchAccounts = () => (dispatch: Dispatch) => {
  request
    .get(`${API_URL}/api/accounts`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then((response) => {
      dispatch(fetchAccountsSuccess(response.body));
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(fetchAccountsError(reason));
    });
};

export const fetchAccountsSuccess = (accounts: Account[]): FetchAccountsSuccessAction => ({
  type: FETCH_ACCOUNTS_SUCCESS,
  payload: {
    accounts,
  },
});

export const fetchAccountsError = (reason: string): FetchAccountsErrorAction => ({
  type: FETCH_ACCOUNTS_ERROR,
  payload: {
    reason,
  },
});

export const updateReport =
  (
    reportId: string,
    name: string,
    description: string,
    visualizationURL: string,
    recurrentNotificationEmail: string,
    callback: () => void,
  ) =>
  (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/report/${reportId}`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send({ name, description, visualizationURL, recurrentNotificationEmail })
      .use(forbiddenHandler)
      .then(() => {
        dispatch(updateReportSuccess(reportId, name, description, visualizationURL, recurrentNotificationEmail));
        callback();
      })
      .catch((error) => {
        const { reason } = error.response.body;
        dispatch(updateReportError(reason));
      });
  };

export const updateReportSuccess = (
  reportId: string,
  name: string,
  description: string,
  visualizationURL: string,
  recurrentNotificationEmail: string,
): UpdateReportSuccessAction => ({
  type: UPDATE_REPORT_SUCCESS,
  payload: {
    reportId,
    name,
    description,
    visualizationURL,
    recurrentNotificationEmail,
  },
});

export const updateReportError = (reason: string): UpdateReportErrorAction => ({
  type: UPDATE_REPORT_ERROR,
  payload: {
    reason,
  },
});

export const updateProject =
  (projectId: string, name: string, description: string, callback: () => void) => (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/projects/${projectId}/meta`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .query({ name, description })
      .use(forbiddenHandler)
      .then(() => {
        dispatch(updateProjectSuccess(projectId, name, description));
        callback();
      })
      .catch((error) => {
        const { reason } = error.response.body;
        dispatch(updateProjectError(reason));
      });
  };

export const updateProjectSuccess = (
  projectId: string,
  name: string,
  description: string,
): UpdateProjectSuccessAction => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: {
    projectId,
    name,
    description,
  },
});

export const updateProjectError = (reason: string): UpdateProjectErrorAction => ({
  type: UPDATE_PROJECT_ERROR,
  payload: {
    reason,
  },
});

export const deleteReport = (projectId: string, reportId: string) => (dispatch: Dispatch) => {
  request
    .delete(`${API_URL}/api/report/${reportId}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then(() => {
      dispatch(deleteReportSuccess(reportId));
    })
    .catch((error) => {
      const { reason } = error.response.body;
      dispatch(deleteReportError(reason));
    });
};

export const deleteReportSuccess = (reportId: string): DeleteReportSuccessAction => ({
  type: DELETE_REPORT_SUCCESS,
  payload: {
    reportId,
  },
});

export const deleteReportError = (reason: string): DeleteReportErrorAction => ({
  type: DELETE_REPORT_ERROR,
  payload: {
    reason,
  },
});

export const recalculateRIs = (reportId: string) => (dispatch: Dispatch) => {
  request
    .put(`${API_URL}/api/reports/${reportId}/refresh`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then(() => {
      dispatch(recalculateRIsSuccess(reportId));
    })
    .catch((error) => {
      const { reason } = error.response.body;
      dispatch(recalculateRIsError(reason));
    });
};

export const recalculateRIsSuccess = (reportId: string): RecalculateRIsSuccessAction => ({
  type: RECALCULATE_RIS_SUCCESS,
  payload: {
    reportId,
  },
});

export const recalculateRIsError = (reason: string): RecalculateRIsErrorAction => ({
  type: RECALCULATE_RIS_ERROR,
  payload: {
    reason,
  },
});

export const deleteProject = (projectId: string) => (dispatch: Dispatch) => {
  request
    .delete(`${API_URL}/api/projects/${projectId}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then(() => {
      dispatch(deleteProjectSuccess(projectId));
    })
    .catch((error) => {
      const { reason } = error.response.body;
      dispatch(deleteProjectError(reason));
    });
};

export const deleteProjectSuccess = (projectId: string): DeleteProjectSuccessAction => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: {
    projectId,
  },
});

export const deleteProjectError = (reason: string): DeleteProjectErrorAction => ({
  type: DELETE_PROJECT_ERROR,
  payload: {
    reason,
  },
});

export function loadReportItemSettingDefinitions(reportCategory: string) {
  return (dispatch: Dispatch) => {
    request
      .get(
        `${API_URL}/api/reportItemSettingDefinitions/${
          reportCategory == null ? IReportCategory.POI_DEFAULT : reportCategory.toUpperCase()
        }`,
      )
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(loadReportItemSettingDefinitionsSuccess(payload.body));
      })
      .catch(console.log);
  };
}

export function editReportItemSettingDefinitions(reportCategory: string, settings: SettingDefinitionsForItems) {
  const actualReportCategory = reportCategory == null ? IReportCategory.POI_DEFAULT : reportCategory.toUpperCase();
  return (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/reportItemSettingDefinitions/${actualReportCategory}`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send({ settings })
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(editReportItemSettingDefinitionsSuccess());
        loadReportItemSettingDefinitions(actualReportCategory)(dispatch);
      })
      .catch(console.log);
  };
}

export function loadReportItemSettingDefinitionsSuccess(body: {
  settings: SettingDefinitionsForItems;
}): LoadReportItemSettingDefinitionsSuccessAction {
  return {
    type: 'LOAD_REPORT_ITEM_SETTINGS_SUCCESS',
    payload: {
      settings: body.settings,
    },
  };
}

export function editReportItemSettingDefinitionsSuccess(): EditReportItemSettingDefinitionsSuccessAction {
  return {
    type: 'EDIT_REPORT_ITEM_SETTINGS_SUCCESS',
    payload: {},
  };
}

export function loadLocations(projectId: string) {
  return (dispatch: Dispatch) => {
    request
      .get(`${API_URL}/api/projects/${projectId}/locations`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(loadLocationsSuccess(payload.body));
      })
      .catch(console.log);
  };
}

export function loadLocationsSuccess(body: { locations: Location[] }): LoadLocationsSuccessAction {
  return {
    type: 'LOAD_LOCATIONS_SUCCESS',
    payload: body,
  };
}

export function createReport(projectId: string, report: Report, callback: Function): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .post(`${API_URL}/api/projects/${projectId}/reports`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send(report)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(createReportSuccess());
        callback();
      })
      .catch((err) => {
        const { reason } = err.response.body;
        dispatch(createReportError(reason));
      });
  };
}

export function createReportSuccess(): CreateReportSuccessAction {
  return {
    type: 'CREATE_REPORT_SUCCESS',
    payload: {},
  };
}

export function createReportError(reason: string): CreateReportErrorAction {
  return {
    type: CREATE_REPORT_ERROR,
    payload: {
      reason,
    },
  };
}

export function createReportItems(projectId: string, report: Report): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .post(`${API_URL}/api/projects/${projectId}/reports/items`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send(report)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(createReportItemsSuccess());
      })
      .catch((err) => {
        const { reason } = err.response.body;
        dispatch(createReportItemsError(reason));
      });
  };
}

export function createReportItemsSuccess(): CreateReportItemsSuccessAction {
  return {
    type: 'CREATE_REPORT_ITEMS_SUCCESS',
    payload: {},
  };
}

export function createReportItemsError(reason: string): CreateReportItemsErrorAction {
  return {
    type: 'CREATE_REPORT_ITEMS_ERROR',
    payload: {
      reason,
    },
  };
}

export function editReportItem(projectId: string, reportItem: ReportItem): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/projects/${projectId}/reports/${reportItem?.reportId}/item/${reportItem?.id}`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send(reportItem)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(editReportItemSuccess());
      })
      .catch((err) => {
        const { reason } = err.response.body;
        dispatch(editReportItemError(reason));
      });
  };
}

export function editReportItemSuccess(): EditReportItemSuccessAction {
  return {
    type: 'EDIT_REPORT_ITEM_SUCCESS',
    payload: {},
  };
}

export function editReportItemError(reason: string): EditReportItemErrorAction {
  return {
    type: 'EDIT_REPORT_ITEM_ERROR',
    payload: {
      reason,
    },
  };
}

export function editReportItemDescription(reportItem: ReportItem): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/projects/reports/${reportItem?.reportId}/item/${reportItem?.id}/meta`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send(reportItem)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(editReportItemDescriptionSuccess());
      })
      .catch((err) => {
        const { reason } = err.response.body;
        dispatch(editReportItemDescriptionError(reason));
      });
  };
}

export function editReportItemDescriptionSuccess(): EditReportItemDescriptionSuccessAction {
  return {
    type: 'EDIT_REPORT_ITEM_DESCRIPTION_SUCCESS',
    payload: {},
  };
}

export function editReportItemDescriptionError(reason: string): EditReportItemDescriptionErrorAction {
  return {
    type: 'EDIT_REPORT_ITEM_DESCRIPTION_ERROR',
    payload: {
      reason,
    },
  };
}

export function deleteReportItem(reportItem: ReportItem): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .delete(`${API_URL}/api/projects/reports/${reportItem?.reportId}/item/${reportItem?.id}`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(deleteReportItemSuccess());
      })
      .catch((err) => {
        const { reason } = err.response.body;
        dispatch(deleteReportItemError(reason));
      });
  };
}

export function deleteReportItemSuccess(): DeleteReportItemSuccessAction {
  return {
    type: 'DELETE_REPORT_ITEM_SUCCESS',
    payload: {},
  };
}

export function deleteReportItemError(reason: string): DeleteReportItemErrorAction {
  return {
    type: 'DELETE_REPORT_ITEM_ERROR',
    payload: {
      reason,
    },
  };
}

export const generateRIThumbnails = (reportItemId: string) => (dispatch: Dispatch) => {
  request
    .post(`${API_URL}/api/reportItems/${reportItemId}/generate-thumbnails`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(generateRIThumbnailsError(reason));
    });
  dispatch(showSuccessMessage(`Thumbnail images for report item '${reportItemId}' will be generated...`));
  setTimeout(() => {
    dispatch(hideSuccessMessageIfRequired());
  }, successBoxDisplayTimeInMillis);
};

export const generateRIThumbnailsSuccess = (): GenerateRIThumbnailsSuccessAction => ({
  type: GENERATE_RI_THUMBNAILS_SUCCESS,
  payload: {},
});

export const generateRIThumbnailsError = (reason: string): GenerateRIThumbnailsErrorAction => ({
  type: GENERATE_RI_THUMBNAILS_ERROR,
  payload: {
    reason,
  },
});

export function showSuccessBox(message: string): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    dispatch(showSuccessMessage(message));
    setTimeout(() => {
      dispatch(hideSuccessMessageIfRequired());
    }, 10000);
  };
}

export function showSuccessMessage(message: string): ShowSuccessMessageAction {
  return {
    type: 'SHOW_SUCCESS_MESSAGE',
    payload: {
      message,
    },
  };
}

export function hideSuccessMessageIfRequired(): HideSuccessIfRequiredMessageAction {
  return {
    type: 'HIDE_SUCCESS_MESSAGE_IF_REQUIRED',
    payload: {},
  };
}

export function loadReports(projectId: string) {
  return (dispatch: Dispatch) => {
    request
      .get(`${API_URL}/api/projects/${projectId}/reports`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(loadReportsSuccess(payload.body));
      })
      .catch(console.log);
  };
}

export function loadReportsSuccess(body: { reports: Report[] }): LoadReportsSuccess {
  return {
    type: 'LOAD_REPORTS_SUCCESS',
    payload: body,
  };
}

export function updateReportItemVisualizationUrlSuccess(): UpdateReportItemVisualizationUrlSuccessAction {
  return {
    type: 'UPDATE_REPORT_ITEM_VISUALIZATION_URL_SUCCESS',
    payload: {},
  };
}

export function updateReportItemVisualizationUrl(
  projectId: string,
  reportId: string,
  reportItemId: string,
  url: string,
) {
  return (dispatch: Dispatch) => {
    request
      .post(
        `${API_URL}/api/projects/${projectId}/reports/${reportId}/reportItems/${reportItemId}/updateVisualisationUrl`,
      )
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send({
        visualisationUrl: url,
      })
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(updateReportItemVisualizationUrlSuccess());
        loadReports(projectId)(dispatch);
      })
      .catch(console.log);
  };
}

export function createCsvPoisError(results: AddPoiResult[]): CreateCsvPoisErrorAction {
  return {
    type: 'CREATE_CSV_POIS_ERROR',
    payload: {
      result: results,
    },
  };
}

export function createPoiSuccess(poiNames: string[], inBatch: boolean): CreatePoiSuccessAction {
  return {
    type: 'CREATE_POI_SUCCESS',
    payload: {
      poiNames,
      inBatch,
    },
  };
}

export function createPoiError(errors: ErrorPoiResult[], inBatch: boolean): CreatePoiErrorAction {
  return {
    type: 'CREATE_POI_ERROR',
    payload: {
      errors,
      inBatch,
    },
  };
}

export function createPoi(projectId: string, pois: Location[], inBatch: boolean, onSuccess?: () => void) {
  return (dispatch: Dispatch) => {
    request
      .post(`${API_URL}/api/projects/${projectId}/locations`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .send(pois)
      .then(() => {
        dispatch(
          createPoiSuccess(
            pois.map(({ name }) => name),
            inBatch,
          ),
        );
        loadLocations(projectId)(dispatch);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        const errors: ErrorPoiResult[] = err.response.body;
        dispatch(createPoiError(errors, inBatch));
      });
  };
}

export function createCsvPois(
  rowsToProcess: any[],
  projectId: string,
  createCsvPoi: (projectId: string, pois: Location[], inBatch: boolean, onSuccess?: () => void) => any,
) {
  return (dispatch: Dispatch) => {
    const results: AddPoiResult[] = [];
    if ((rowsToProcess?.length || 0) <= 0) {
      results.push({
        name: '',
        message: 'There is no data at csv file to process',
        type: 'failure',
      });
    } else {
      rowsToProcess
        ?.filter(({ data }) => data.length != 1 && data[0] !== '')
        ?.map(({ data }, index) => {
          if (data.length !== 11) {
            results.push({
              name: '',
              message: `Invalid number of values for location on row ${index + 1}, expected: "11", got: "${
                data.length
              }"`,
              type: 'failure',
            });
          } else if (!data[0]) {
            results.push({
              name: '',
              message: `"${data[0]}" is not a valid name for location on row ${index + 1}`,
              type: 'failure',
            });
          } else if (!data[10]) {
            results.push({
              name: data[0],
              message: `"${data[10]}" is not a valid polygon for location on row ${index + 1}`,
              type: 'failure',
            });
          }
          return null;
        })
        .filter((item) => item);
    }

    if (results.length > 0) {
      dispatch(createCsvPoisError(results));
    } else {
      const pois = rowsToProcess
        ?.filter(({ data }) => data.length !== 1 && data[0] !== '')
        .map((row: any) => {
          const { data } = row;
          const name = data[0];
          const polygonInput = data[10] ? data[10].replace('N((', 'N ((') : data[10];
          return {
            name,
            description: data[1],
            address: data[2],
            street: data[3],
            houseNumber: data[4],
            city: data[5],
            country: data[6],
            postalCode: data[7],
            purpose: data[8],
            setup: data[9],
            polygon: {
              wktRepresentation: polygonInput,
            },
          };
        });
      createCsvPoi(projectId, pois as Location[], true);
    }
  };
}

export function deletePoisSuccess(): DeletePoisSuccessAction {
  return {
    type: 'DELETE_POIS_SUCCESS',
    payload: {},
  };
}

export function deletePoisError(reason: string): DeletePoisErrorAction {
  return {
    type: 'DELETE_POIS_ERROR',
    payload: {
      reason,
    },
  };
}

export function deletePois(projectId: string, poiIds: string[]) {
  return (dispatch: Dispatch) => {
    request
      .delete(`${API_URL}/api/projects/${projectId}/locations`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send({ locationIds: poiIds })
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(deletePoisSuccess());
        loadLocations(projectId)(dispatch);
      })
      .catch((err) => {
        const { reason } = err.response.body;
        dispatch(deletePoisError(reason));
      });
  };
}

export function resetBatchPoiUploadResults(): ResetBatchPoiUploadResults {
  return {
    type: 'RESET_BATCH_POI_UPLOAD_RESULTS',
    payload: {},
  };
}

export function loadReportItemTypes(reportCategory: string) {
  return (dispatch: Dispatch) => {
    request
      .get(
        `${API_URL}/api/reportItems/types/${
          reportCategory == null ? IReportCategory.POI_DEFAULT : reportCategory.toUpperCase()
        }`,
      )
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(loadReportItemTypesSuccess(payload.body.types));
      })
      .catch(console.log);
  };
}

function loadReportItemTypesSuccess(types: string[]): LoadReportItemTypesSuccess {
  return {
    type: 'LOAD_REPORT_ITEM_TYPES',
    payload: {
      types,
    },
  };
}

export function rerunReportWithPostProcessingSettings(settingsArr: any, report: Report) {
  const reportId = report.id;
  return (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/report/${reportId}/edit`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send(settingsArr)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(rerunReportWithPostProcessingSettingsSuccess(payload.status.toString(), reportId));
      })
      .catch((err) => {
        if(err.status !== undefined){
          dispatch(rerunReportWithPostProcessingSettingsError(err.status.toString(), reportId));
        }
      });
  };
}

export function rerunReportWithPostProcessingSettingsSuccess(
  code: string,
  reportId: any,
): CreatePostProcessingSettingsSuccess {
  return {
    type: 'CREATE_PPS_SUCCESS',
    payload: {
      code,
      reportId,
    },
  };
}

export function rerunReportWithPostProcessingSettingsError(reason: string, reportId: any): CreatePostProcessingSettingsError {
  return {
    type: CREATE_PPS_ERROR,
    payload: {
      reason,
      reportId,
    },
  };
}

export function editPostProcessingSettings() {
  // TODO function args
  return (dispatch: Dispatch) => {
    request
      .put(`${API_URL}/api/report/updates/edit`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(editPostProcessingSettingsSuccess(payload.body)); // TODO args
      })
      .catch((err) => {
        const { reason } = err.response.body;
        dispatch(editPostProcessingSettingsError(reason));
      });
  };
}

export function editPostProcessingSettingsSuccess(types: string[]): EditPostProcessingSettingsSuccessAction {
  // TODO args
  return {
    type: EDIT_POST_PROCESSING_SETTINGS_SUCCESS,
    payload: {}, // TODO payload
  };
}

export function editPostProcessingSettingsError(reason: string): EditPostProcessingSettingsErrorAction {
  return {
    type: EDIT_POST_PROCESSING_SETTINGS_ERROR,
    payload: {
      reason,
    },
  };
}

export const deletePostProcessingSettings = (name: string) => (dispatch: Dispatch) => {
  // TODO is this enough?
  request
    .delete(`${API_URL}/api/report/updates/delete/{name}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then(() => {
      dispatch(deletePostProcessingSuccess(name)); // TODO args
    })
    .catch((error) => {
      const { reason } = error.response.body;
      dispatch(deletePostProcessingError(reason));
    });
};

export const deletePostProcessingSuccess = (projectId: string): DeletePostProcessingSettingsSuccessAction => ({
  // TODO args
  type: DELETE_POST_PROCESSING_SETTINGS_SUCCESS,
  payload: {
    // TODO payload
    projectId,
  },
});

export const deletePostProcessingError = (reason: string): DeletePostProcessingSettingsErrorAction => ({
  type: DELETE_POST_PROCESSING_SETTINGS_ERROR,
  payload: {
    reason,
  },
});

export const getAllPostProcessingSettings = () => (dispatch: Dispatch) => {
  // TODO args
  request
    .delete(`${API_URL}/api/report/updates/delete/{name}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then(() => {
      dispatch(getAllPostProcessingSuccess()); // TODO args
    })
    .catch((error) => {
      const { reason } = error.response.body;
      dispatch(getAllPostProcessingError(reason));
    });
};

export const getAllPostProcessingSuccess = (): GetAllPostProcessingSettingsSuccessAction => ({
  // TODO args
  type: GET_ALL_POST_POST_PROCESSING_SETTINGS_SUCCESS,
  payload: {
    // TODO payload
  },
});

export const getAllPostProcessingError = (reason: string): GetAllPostProcessingSettingsErrorAction => ({
  type: GET_ALL_POST_POST_PROCESSING_SETTINGS_ERROR,
  payload: {
    reason,
  },
});

export const getPostProcessingSettingsByName = (name: string) => (dispatch: Dispatch) => {
  // TODO args
  request
    .delete(`${API_URL}/api/report/updates/find/{name}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then(() => {
      dispatch(getPostProcessingByNameSuccess(name)); // TODO args
    })
    .catch((error) => {
      const { reason } = error.response.body;
      dispatch(getPostProcessingByNameError(reason));
    });
};

export const getPostProcessingByNameSuccess = (projectId: string): GetPostProcessingSettingsByNameSuccessAction => ({
  // TODO args
  type: GET_POST_PROCESSING_SETTINGS_BY_NAME_SUCCESS,
  payload: {
    // TODO payload
    projectId,
  },
});

export const getPostProcessingByNameError = (reason: string): GetPostProcessingSettingsByNameErrorAction => ({
  type: GET_POST_PROCESSING_SETTINGS_BY_NAME_ERROR,
  payload: {
    reason,
  },
});

export const getPostProcessingSettingsByCategory = (category: string) => (dispatch: Dispatch) => {
  // TODO args
  request
    .delete(`${API_URL}/api/report/updates/find/{category}`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .then(() => {
      dispatch(getPostProcessingByCategorySuccess(category)); // TODO args
    })
    .catch((error) => {
      const { reason } = error.response.body;
      dispatch(getPostProcessingByCategoryError(reason));
    });
};

export const getPostProcessingByCategorySuccess = (
  projectId: string,
): GetPostProcessingSettingsByCategorySuccessAction => ({
  // TODO args
  type: GET_POST_PROCESSING_SETTINGS_BY_CATEGORY_SUCCESS,
  payload: {
    // TODO payload
    projectId,
  },
});

export const getPostProcessingByCategoryError = (reason: string): GetPostProcessingSettingsByCategoryErrorAction => ({
  type: GET_POST_PROCESSING_SETTINGS_BY_CATEGORY_ERROR,
  payload: {
    reason,
  },
});

export function createProject(project: Project, callback: Function): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .post(`${API_URL}/api/projects`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .send(project)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(createProjectSuccess());
        callback();
      })
      .catch((err) => {
        const { reason } = err.response.body;
        dispatch(createProjectError(reason));
      });
  };
}

export function createProjectSuccess(): CreateProjectSuccessAction {
  return {
    type: 'CREATE_PROJECT_SUCCESS',
    payload: {},
  };
}

export function createProjectError(reason: string): CreateProjectErrorAction {
  return {
    type: CREATE_PROJECT_ERROR,
    payload: {
      reason,
    },
  };
}

export function loadCountries() {
  return (dispatch: Dispatch) => {
    request
      .get(`${API_URL}/api/countries`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .catch(console.log);
  };
}

function loadCountriesSuccess(countries: Country[]): LoadCountriesSuccessAction {
  return {
    type: 'LOAD_COUNTRIES_SUCCESS',
    payload: {
      countries,
    },
  };
}

export function loadProjects(accountIds: string[]) {
  const accountId = accountIds.length === 0 ? '' : `?accountId=${accountIds.join(',')}`;
  return (dispatch: Dispatch) => {
    request
      .get(`${API_URL}/api/projects${accountId}`)
      .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
      .use(forbiddenHandler)
      .then((payload) => {
        dispatch(loadProjectsSuccess(payload.body.projects));
      })
      .catch(console.log);
  };
}

function loadProjectsSuccess(projects: Project[]): LoadProjectsSuccessAction {
  return {
    type: 'LOAD_PROJECTS_SUCCESS',
    payload: {
      projects,
    },
  };
}

function getLoginFailReason(err: any): string {
  try {
    return err.response.body.reason;
  } catch (err) {
    return 'Login failed';
  }
}

export function login(email: string, password: string): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .post(`${API_URL}/api/authenticate/login`)
      .send({ email, password })
      .use(forbiddenHandler)
      .then((payload) => {
        const { token } = payload.body;
        dispatch(loginSuccess(token));
      })
      .catch((err) => {
        const reason = getLoginFailReason(err);
        dispatch(loginError(reason));
      });
  };
}

export function loginSuccess(token: string): LoginSuccessAction {
  return {
    type: 'LOGIN_SUCCESS',
    payload: {
      token,
    },
  };
}

export function loginError(reason: string): LoginErrorAction {
  return {
    type: 'LOGIN_ERROR',
    payload: {
      reason,
    },
  };
}

export function forgotPassword(
  email: string,
  captchaToken: string,
  callbackOnSuccess: () => void,
): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .post(`${API_URL}/api/users/forgotPassword`)
      .send({ email, captchaToken })
      .then((payload) => {
        dispatch(forgotPasswordSuccess());
        callbackOnSuccess();
      })
      .catch((error) => {
        const reason = error?.response?.body?.reason;
        dispatch(forgotPasswordError(reason));
      });
  };
}

export function forgotPasswordSuccess(): ForgotPasswordSuccessAction {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: {},
  };
}

export function forgotPasswordError(reason: string): ForgotPasswordErrorAction {
  return {
    type: FORGOT_PASSWORD_ERROR,
    payload: {
      reason,
    },
  };
}

export function resetPassword(
  token: string,
  password: string,
  repeatPassword: string,
  callbackOnSuccess: () => void,
): PlacenseThunkAction {
  return (dispatch: Dispatch) => {
    request
      .post(`${API_URL}/api/users/resetPassword`)
      .send({ token, password, repeatPassword })
      .then((payload) => {
        dispatch(resetPasswordSuccess());
        callbackOnSuccess();
      })
      .catch((error) => {
        const reason = error?.response?.body?.reason;
        dispatch(resetPasswordError(reason));
      });
  };
}

export function resetPasswordSuccess(): ResetPasswordSuccessAction {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: {},
  };
}

export function resetPasswordError(reason: string): ResetPasswordErrorAction {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: {
      reason,
    },
  };
}

export const addAccount = (newAccount: AddAccount, callbackOnSuccess: () => void) => (dispatch: Dispatch) => {
  request
    .post(`${API_URL}/api/accounts`)
    .set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
    .use(forbiddenHandler)
    .send({
      ...newAccount,
    })
    .then(() => {
      dispatch(addAccountSuccess());
      callbackOnSuccess();
    })
    .catch((error) => {
      const reason = error?.response?.body?.reason;
      dispatch(addAccountError(reason));
    });
};

export const addAccountSuccess = (): AddAccountSuccessAction => ({
  type: ADD_ACCOUNT_SUCCESS,
  payload: {},
});

export const addAccountError = (reason: string): AddAccountErrorAction => ({
  type: ADD_ACCOUNT_ERROR,
  payload: {
    reason,
  },
});

export const getProjectStatus = async (projectId: string): Promise<string> => {
  const url = `${API_URL}/api/projects/${projectId}/status`;
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });
  return (await response.text()) as string;
};

export const getProjectStatusSuccess = (): GetProjectStatusSuccessAction => ({
  type: GET_PROJECT_STATUS_SUCCESS,
  payload: {},
});

export const getProjectStatusError = (reason: string): GetProjectStatusErrorAction => ({
  type: GET_PROJECT_STATUS_ERROR,
  payload: {
    reason,
  },
});

export const getOptionsProjectStatuses = async (): Promise<[string]> => {
  const url = `${API_URL}/api/projects/statuses/values`;
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });
  return (await response.json()) as [string];
};

export const getOptionsProjectStatusesSuccess = (): GetOptionsProjectStatusesSuccessAction => ({
  type: GET_OPTIONS_PROJECT_STATUSES_SUCCESS,
  payload: {},
});

export const getOptionsProjectStatusesError = (reason: string): GetOptionsProjectStatusesErrorAction => ({
  type: GET_OPTIONS_PROJECT_STATUSES_ERROR,
  payload: {
    reason,
  },
});

export const getSelectedUsers = async (projectId: string): Promise<SharedUser[]> => {
  try {
    const url = `${API_URL}/api/collaboration/project/${projectId}`;

    const response = await fetch(url, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    });

    return response.json();
  } catch (error) {
    return [];
  }
};

export const saveSelectedUsers = async (
  projectId: string,
  users: { id: string | number; shared: boolean }[],
): Promise<any> => {
  const url = `${API_URL}/api/collaboration/project/${projectId}`;

  await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
    body: JSON.stringify(users),
  });
};

export const getProjectCollaborationType = async (projectId: string): Promise<any> => {
  const url = `${API_URL}/api/projects/${projectId}/collaboration`;

  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  if (response.status === 404) return null;
  return response.json();
};

export const saveProjectCollaborationType = async (projectId: string, collaborationType: CollaborationEnum) => {
  const url = `${API_URL}/api/projects/${projectId}/collaboration/${collaborationType}`;

  await fetch(url, {
    method: 'PUT',
    headers: {
      authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });
};
