/* eslint-disable */
const IS_PRODUCTION =
  process.env.REACT_APP_API_URL === 'https://portal-prod-1-dot-gifted-slice-218906.ey.r.appspot.com';
const IS_QA = process.env.REACT_APP_API_URL === 'https://portal-qa-dot-gifted-slice-218906.ey.r.appspot.com';
const IS_DEV = process.env.REACT_APP_API_URL === 'https://portal-dev-dot-gifted-slice-218906.ey.r.appspot.com';

if (IS_DEV) {
  window.usetifulTags = { userId: 'EXAMPLE_USER_ID' };
  (function (w, d, s) {
    var a = d.getElementsByTagName('head')[0];
    var r = d.createElement('script');
    r.async = 1;
    r.src = s;
    r.setAttribute('id', 'usetifulScript');
    r.dataset.token = '626e8a7e7935e6db02ae2172b6349786';
    a.appendChild(r);
  })(window, document, 'https://www.usetiful.com/dist/usetiful.js');
}
