import React, { useEffect, useRef, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import SliderIcon from '../../icons/controls/slider';
import styles from './style.module.scss';
import { useClickOutside } from '../../hooks';
import { toFixNumber } from '../../lib';

interface Props {
  onChange: (data: { min: number; max: number }) => void;
  initialValue: { min: number; max: number };
  maxValue: number;
  minValue: number;
  disabled?: boolean;
  percentage?: boolean; // if true, values are multiplied by 100
  unitSymbol?: string;
}

export const ThresholdFilter: React.FC<Props> = ({
  onChange,
  initialValue,
  maxValue,
  minValue,
  disabled = false,
  percentage = true,
  unitSymbol = '',
}) => {
  const max = maxValue;
  const min = minValue;
  const [values, setValues] = useState<number[]>([min, max]);
  const [inputValues, setInputValues] = useState<number[]>([min, max]);
  const [inputValuesPct, setInputValuesPct] = useState<number[]>([
    toFixNumber(min * 100, 2),
    toFixNumber(max * 100, 2),
  ]);
  const [isOverInput, setIsOverInput] = useState<boolean[]>([false, false]);
  const minRef = useRef<HTMLInputElement>(null);
  const maxRef = useRef<HTMLInputElement>(null);
  const [isManualEditModeEnabled, setIsManualEditModeEnabled] = useState<boolean[]>([false, false]);

  useEffect(() => {
    const { min, max } = initialValue;
    if (min !== 0 || max !== 0) {
      setValues([min, max]);
      setInputValues([min, max]);
      setInputValuesPct([toFixNumber(min * 100, 2), toFixNumber(max * 100, 2)]);
    }
  }, [initialValue]);

  const onConfirmInputChange = (index: number) => {
    const ref = index === 0 ? minRef : maxRef;
    ref?.current?.blur();
    if (isManualEditModeEnabled[index]) {
      let newValue = Number(inputValuesPct[index] / 100);
      if (Number.isNaN(newValue)) {
        newValue = index === 0 ? initialValue.min : initialValue.max;
      } else if (newValue < min) {
        newValue = min;
      } else if (newValue > max) {
        newValue = max;
      }
      setValues([newValue]);
      setInputValues((prevState) => prevState.map((item, arrayIndex) => (index === arrayIndex ? newValue : item)));
      onChange({ min: index === 0 ? newValue : inputValues[0], max: index === 1 ? newValue : inputValues[1] });
    }
    setIsManualEditModeEnabled((prevState) => prevState.map(() => false));
  };

  useClickOutside(minRef, () => onConfirmInputChange(0), isManualEditModeEnabled[0]);
  useClickOutside(maxRef, () => onConfirmInputChange(1), isManualEditModeEnabled[1]);

  const onThumbFinalChangeHandler = ([min, max]: number[]) => {
    const payload = {
      min,
      max,
    };
    if (isOverInput.every((item) => !item) && isManualEditModeEnabled.every((item) => !item)) {
      onChange(payload);
    }
  };

  const onThumbChangeHandler = (selectedValues: number[]) => {
    if (isOverInput.every((item) => !item) && isManualEditModeEnabled.every((item) => !item)) {
      setValues(selectedValues);
      setInputValues(selectedValues);
      setInputValuesPct(selectedValues.map((value) => toFixNumber(value * 100, 2)));
    }
  };

  const onInputClickHandler = (index: number) => {
    const ref = index === 0 ? minRef : maxRef;
    ref?.current?.focus();
    setIsManualEditModeEnabled((prevState) => prevState.map((item, arrayIndex) => index === arrayIndex));
  };

  const onInputMouseOverHandler = (index: number) => {
    setIsOverInput((prevState) => prevState.map((item, arrayIndex) => index === arrayIndex));
  };

  const onInputMouseOutHandler = () => {
    setIsOverInput((prevState) => prevState.map(() => false));
  };

  const onBlur = (index: number) => {
    if (isManualEditModeEnabled[index]) {
      onConfirmInputChange(index);
    }
    setIsOverInput((prevState) => prevState.map(() => false));
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    event.persist();
    setInputValuesPct((prevState) =>
      prevState.map((item, arrayIndex) =>
        index === arrayIndex ? (percentage ? event.target.valueAsNumber : event.target.valueAsNumber * 100) : item,
      ),
    );
  };

  const onInputKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Enter') {
      onConfirmInputChange(index);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.slider}>
        <span className={`${styles.value} ${styles.left}`}>
          {percentage ? `${(min * 100).toFixed(min !== 0 ? 2 : 0)}${unitSymbol}` : `${min.toFixed(0)}${unitSymbol}`}
        </span>
        <Range
          step={percentage ? 0.0001 : 100}
          min={min}
          max={min < max ? max : max + 1}
          values={values}
          onChange={onThumbChangeHandler}
          disabled={disabled}
          onFinalChange={onThumbFinalChangeHandler}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
                minWidth: '100px',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '10px',
                  width: '100%',
                  background: getTrackBackground({
                    values,
                    colors: ['rgba(255, 255, 255, 0.15)', '#00B2F1', 'rgba(255, 255, 255, 0.15)'],
                    min,
                    max,
                  }),
                  alignSelf: 'center',
                  borderRadius: '15px',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, ...rest }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '10px',
                width: '0px',
                backgroundColor: '#00434B',
                content: '',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span className={styles.icon}>
                <SliderIcon />
                <input
                  ref={rest.index === 0 ? minRef : maxRef}
                  className={`${isManualEditModeEnabled[rest.index] ? '' : styles.disabled}`}
                  type={isManualEditModeEnabled[rest.index] ? 'number' : 'string'}
                  maxLength={6}
                  max={max}
                  min={min}
                  size={6}
                  value={
                    isManualEditModeEnabled[rest.index]
                      ? Number(
                          percentage
                            ? inputValuesPct[rest.index].toFixed(2)
                            : (inputValuesPct[rest.index] / 100).toFixed(0),
                        )
                      : percentage
                      ? `${parseFloat(`${(inputValues[rest.index] * 100).toFixed(2)}`)}${unitSymbol}`
                      : `${parseFloat(`${inputValues[rest.index].toFixed(0)}`)}${unitSymbol}`
                  }
                  onChange={(e) => onInputChange(e, rest.index)}
                  onClick={() => onInputClickHandler(rest.index)}
                  onMouseOver={() => onInputMouseOverHandler(rest.index)}
                  onFocus={() => onInputMouseOverHandler(rest.index)}
                  onBlur={() => onBlur(rest.index)}
                  onMouseOut={onInputMouseOutHandler}
                  onKeyUp={(e) => onInputKeyUpHandler(e, rest.index)}
                  disabled={disabled}
                />
              </span>
            </div>
          )}
        />
        <span className={`${styles.value} ${styles.right}`}>
          {percentage ? `${(max * 100).toFixed(2)}${unitSymbol}` : `${max.toFixed(0)}${unitSymbol}`}
        </span>
      </div>
    </div>
  );
};
