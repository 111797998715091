import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as turf from '@turf/turf';
import { useStore } from 'effector-react';
import {
  generatePolygonString,
  PolygonTool,
  splitCoordinatesArrayToPairs,
  splitCoordinatesByPolygons,
} from '../../polylineTool';
import { Form } from './form';
import styles from './style.module.scss';
import { PolygonData } from './interfaces';
import {
  PolygonSizeValidationError,
  ValidationError,
  ValidationErrorType,
} from '../locationManager/validation/validationErrorType';
import { LocationManagerModel } from '../model';
import isMaxNumberOfClustersReached from '../locationManager/validation/isMaxNumberOfClustersReached';
import createPolygon from '../locationManager/validation/createPolygon';
import { MainContext } from '../../types/mainContext';
import { IReportCategory } from '../../../../types/report';

interface Props {
  onCancel: VoidFunction;
  projectId: string;
  onClose: VoidFunction;
  editedLocation: PolygonData | null;
  onValidationError: (error: ValidationError) => void;
  isEdit?: boolean; // isEdit = true means "manage locations" functionality, isEdit = false means "create report" functionality
  reportCategory?: IReportCategory;
}

const convertPolygonsToPoints = (data: string[]) =>
  data.map((polygon) => {
    const coordinates = splitCoordinatesArrayToPairs(polygon.split(','));
    return coordinates.map(([longitude, latitude]) => ({
      longitude: String(longitude),
      latitude: String(latitude),
    }));
  });

export const CreateLocation: React.FC<Props> = ({
  onCancel,
  projectId,
  onClose,
  editedLocation,
  onValidationError,
  isEdit = false,
  reportCategory,
}) => {
  const [coordinates, setCoordinates] = useState('');
  const [manuallyModifiedCoordinates, setManuallyModifiedCoordinates] = useState('');
  const selectedLocations = useStore(LocationManagerModel.$selectedLocationsInformation);
  const {
    limitations: { polygonSize, aoiPolygonSize, maximumNumberOfClusters },
  } = useContext(MainContext);

  const onChangePolygon = (data: string) => {
    setCoordinates(data);
  };

  const onSubmit = (data: PolygonData) => {
    const { polygonString, id, ...rest } = data;
    const parsedPolygon = splitCoordinatesByPolygons(polygonString.split('\n'));
    const polygon = createPolygon(data);
    const calculatedPolygonSize = turf.area(polygon);

    if (
      calculatedPolygonSize >=
      (!isEdit && reportCategory === IReportCategory.POI_DEFAULT ? polygonSize : aoiPolygonSize)
    ) {
      onValidationError({
        type: ValidationErrorType.POLYGON_SIZE,
        size: calculatedPolygonSize,
      } as PolygonSizeValidationError);
    } else if (!isEdit && isMaxNumberOfClustersReached(polygon, selectedLocations, maximumNumberOfClusters)) {
      onValidationError({ type: ValidationErrorType.NUMBER_OF_CLUSTERS });
    } else {
      const isMultiple = parsedPolygon.length > 1;
      const payload = {
        address: '',
        houseNumber: '',
        ...rest,
        id: id.length > 0 ? id : uuidv4(),
        polygonString,
        polygon: {
          points: !isMultiple ? convertPolygonsToPoints(parsedPolygon)[0] : undefined,
          wktRepresentation: isMultiple ? generatePolygonString(parsedPolygon) : undefined,
        },
        isNew: true,
        isUsed: false,
      } as PolygonData;

      if (isEdit) {
        const onSuccess = () => {
          LocationManagerModel.getLocations(projectId);
        };
        if (editedLocation) {
          LocationManagerModel.editLocation({
            projectId,
            locationId: data.id,
            name: data.name,
            description: data.description,
            onSuccess,
          });
        } else {
          LocationManagerModel.createLocation({ projectId, data, onSuccess });
        }
      } else if (editedLocation) {
        LocationManagerModel.updateLocation(payload);
      } else {
        LocationManagerModel.saveLocations([payload]);
      }
      onClose();
    }
  };
  useEffect(() => {
    const element = document.getElementById(`polygonToolSearchFieldAutoComplete`);
    if (element) {
      element?.focus();
    }
  }, []);

  return (
    <div className={styles.container}>
      <PolygonTool
        onChangePolygon={onChangePolygon}
        coordinates={manuallyModifiedCoordinates}
        editedLocation={editedLocation}
      />
      <Form
        onCancel={onCancel}
        coordinates={coordinates}
        onSetPolygon={setManuallyModifiedCoordinates}
        onSubmit={onSubmit}
        editedLocation={editedLocation}
        isEdit={isEdit}
      />
    </div>
  );
};
